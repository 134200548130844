<template>
    <v-container fluid>
        <v-card>
            <v-card-text>
        <v-row row wrap>
            <template v-if="loading">
                <v-col cols="12" class="text-center" mt-4>
                    <h3>Loading Report</h3>
                <v-progress-circular
                    :width="3"
                    color="blue"
                    indeterminate
                ></v-progress-circular>
                </v-col>
            </template>
            <template v-else>
                <template v-if="statReport!=[]">
                <v-col cols="12">

                    <v-expansion-panels>
                        <v-expansion-panel>
                        <v-expansion-panel-header>
                            <h4> Email Report: "{{item.subject}}"</h4>
                            <h5> Sent to active members: {{item.sendToActiveMembers}} <br>
                                    Sent to future members: {{item.sendToFutureMembers}} <br> .
                            </h5>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p>{{item.body.text}}</p>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                  <v-data-table 
                    dense
                    hide-default-header
                    hide-default-footer
                    :headers="reportHeaders"
                    :items="statReport"
                    item-key="name"
                    class="elevation-1"
                ></v-data-table>
                <v-data-table 
                    dense
                    :headers="notReachedHeaders"
                    :items="notReached"
                    item-key="name"
                    class="elevation-1"
                ></v-data-table>
                </v-col>               
                </template>
            </template>
        </v-row>
            </v-card-text>
        <v-card-actions>
        <v-btn v-if="notReached.length>0" color="warning" outlined @click="resendEmail(item)">
            Re-send email to unreached members
        </v-btn>
        </v-card-actions>
        <v-dialog v-model="resending" persistent max-width="50%">
            <v-card>
            <v-card-title>
            Resending email to unreached members
            </v-card-title>
            <v-card-subtitle>
                <v-col cols="12" class="text-center mt-4">
                <v-progress-circular
                    :width="3"
                    color="blue"
                    indeterminate
                ></v-progress-circular>
                </v-col>
            </v-card-subtitle>
            </v-card>
        </v-dialog>
        </v-card>
    </v-container>
</template>

<script>
import moment from 'moment-timezone'
import VueJsonToCsv from 'vue-json-to-csv'
import axios from 'axios'
export default {
    components: {
        VueJsonToCsv
    },
    data () {
        return {
            search: '',
            reportHeaders: [{text:"Name", value: "name"}, {text:"Value", value:"value"}],
            notReachedHeaders: [{text:"Name", value: "fullName"}, {text:"Email", value:"email"}],
            loading: false,
            expanded: [],
            stats: {},
            messages: [],
            memberStats: {},
            notReached: [],
            resending: false,
        }
    },
    props: ['item', 'members'],
    mounted () {
        this.refresh()
    },
    watch: {
        item () {
            this.refresh()
        }
    },
    computed: {
        statReport () {
            let report = []
            if(this.stats != undefined && this.memberStats != undefined){
                report.push({name: "Total Emails Sent", value: this.stats.Sent})
                report.push({name: "Bounced", value: this.stats.Bounced})
                report.push({name: "Opens", value: this.stats.Opens})
                report.push({name: "Unique Opens", value: this.stats.UniqueOpens})
                report.push({name: "Spam Complaints", value: this.stats.SpamComplaints})
                report.push({name: "Members Reached", value: this.memberStats.emailed})
                report.push({name: "Members Not Reached", value: this.memberStats.notEmailed})
            }
            return report
        }
    },
    methods: {
        refresh () {
            if(this.item != null)
                this.getReport()
        },
        calendar (dateStr) {
            return moment(dateStr, "YYYY-MM-DD @HH:mm").calendar()
        },
        
        getReport () {
            this.loading = true
            this.confirmImport = false
            let uid = this.$store.state.user.uid
            const functionServer = ENV.functionsServer()
            let self = this
            return new Promise( function(resolve) {
                axios.post(functionServer + "getmessagingreport", {uid: uid, subject: self.item.subject})
                .then( response => {
                    self.messages = response.data.data.Messages
                    self.stats = response.data.data.stats
                    self.notReached = []
                    self.memberStats.emailed = 0
                    self.memberStats.notEmailed = 0
                    self.members.forEach( member => {
                        if((self.item.sendToActiveMembers && self.isActiveMember(member)) || (self.item.sendToFutureMembers && self.isFutureMember(member))){
                            let emailed = false
                            self.messages.forEach( message => {
                                if(message.Recipients[0] == member.email && message.Status == "Sent"){
                                    emailed = true
                                    self.memberStats.emailed += 1
                                    member.emailStatus = "Sent"
                                }
                            })
                            if(!emailed){
                                self.notReached.push(member)
                                self.memberStats.notEmailed += 1
                                member.emailStatus = "Not Sent"
                                //console.log(member.fullName)
                            }
                        }
                    })
                    self.loading = false
                });
            });  
        },

        resendEmail (item) { //this function is only accessible after sending to admin, enforced by dialog popup sequence
            this.resending = true
            let uid = this.$store.state.user.uid
            let self = this
            const functionServer = ENV.functionsServer()
            let sendTo = this.notReached
            // sendTo = [ //for debugging, send only to me
            //     {
            //         email: "whammond@gmail.com",
            //         firstName: "Bill",
            //         userStatus: "active",
            //         userType: "employee",
            //         correspondence: true,
            //     }
            // ]
            return new Promise( function(resolve) {
                axios.post(functionServer + "resendEmail", 
                {   uid: uid, 
                    email: item, 
                    members: sendTo
                })
                .then( function(response) {
                    self.getReport()
                    self.resending = false 
                    resolve(response)
                    reset()
                })
                .catch (error => {
                    console.log(error)
                    self.resending = false
                })
            });  
        },

        isActiveMember(user){
            if(user == null || user == undefined || user.userStatus == "invitee")
                return false
            else{
                let now = moment().format("YYYY-MM-DD HH:mm")
                let start = user.joinDate
                let end = user.expireDate
                if(start == undefined || start == null) //these were not created for beginning of app 2019. only update if member renewed
                start = this.currentMembershipYear + "-01-01 00:00"
                if(end == undefined || end == null)
                end = this.currentMembershipYear + "-12-31 23:59"
            if(now >= start && now <= end)
                return true
            else
                return false
            }
        },
        isFutureMember(user){
            if(user == null || user == undefined || user.userStatus == "invitee" || user.joinDate == undefined || user.expireDate == undefined)
                return false
            else{
                return !this.isActiveMember(user)
            }
        }
    }
}
</script>